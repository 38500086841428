<template>
  <div class="container text-center">
    <div class="progress w-100 mb-3 p-0" style="height: 5px">
      <div
        class="progress-bar bg-success p-0"
        role="progressbar"
        style="width: 100%"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <p>100%</p>
    <img src="../../assets/icons/check.svg" class="my-4" alt="" />
    <div class="mt-4">
      <h2 class="mb-2">{{ section.title }}</h2>
      <p>{{ section.description }}</p>
      <button
        v-show="path.includes('valorization')"
        @click="sendAnswers"
        class="btn btn-outline-success w-100 mt-4 mb-3"
      >
        {{ section.redirectButton }}
      </button>
      <button
        v-show="path.includes('assesment')"
        @click="$router.push('/questionnaire/valorization')"
        class="btn btn-outline-success w-100 mt-4 mb-3"
      >
        {{ section.redirectButton }}
      </button>
    </div>
    <button
      @click="$router.push('/questionnaire')"
      class="btn btn-outline-success w-100"
    >
      {{ section.backButton }}
    </button>
  </div>
</template>

<script>
import { sendAnswers } from "../../helpers/questions";
import store from "../../store";

export default {
  name: "FormCompleted",
  components: {},
  props: {},
  data() {
    return {
      path: this.$route.path,
      section: {},
    };
  },
  mounted() {
    this.fetchData(this.path);
  },
  computed: {},
  watch: {
    "$store.state.sections": function () {
      this.fetchData(this.path);
      console.log(this.section);
    },
  },
  methods: {
    async sendAnswers() {
      {
        const data = {
          idUser: JSON.parse(localStorage.userData)._id,
          email: JSON.parse(localStorage.userData).email,
          language: this.$store.getters.getLanguage,
        };
        const response = await sendAnswers(data);
        if (response.status === 200) {
          await this.$router.push("/questionnaire");
        }
      }
    },
    fetchData(path) {
      if (path.includes("valorization")) {
        if (store.state.sections.valorizationSuccess) {
          this.section = store.state.sections.valorizationSuccess.components;
        }
      } else {
        if (store.state.sections.assessmentSuccess) {
          this.section = store.state.sections.assessmentSuccess.components;
        }
      }
    },
  },
};
</script>

<style scoped></style>
