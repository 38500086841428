<template>
  <div class="__options d-flex">
    <div class="dropdown">
      <button class="btn-icon reverse" type="button" id="userOptions"  data-bs-toggle="dropdown" aria-expanded="false">
        <img
          src="@/assets/icons/user.svg"
          loading="lazy"
          alt="user-icon"
        />
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userOptions" v-if="userAccessRoute">
<!--        <li>-->
<!--          <button class="dropdown-item py-2">Editar perfil</button>-->
<!--        </li>-->
        <li>
          <button class="dropdown-item py-2" @click="logout">Cerrar sesión</button>
        </li>
      </ul>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userOptions" v-else>
        <li>
          <button class="dropdown-item py-2" @click="() => this.$router.push('/login')">
            {{getLanguage === 'ES' ? 'Iniciar session' : 'Log in'}}
          </button>
        </li>
      </ul>
    </div>
<!--    <button class="btn-icon" :class="{'bg-green': button.role === language}" type="button" @click="changeLanguage(button.role)"-->
<!--            v-for="button in buttons"-->
<!--            :key="button.id">-->
<!--      {{ button.text }}-->
<!--    </button>-->
    <div class="d-flex align-items-center">
      <div  @click="changeLanguage(button.role)" class="button-language" v-for="button in buttons" :key="button.id">
        <small>{{button.text}}</small>
        <img :src="require(`../../assets/icons/${button.flag}`)"  class="language-icon"  alt="language flag icon">
      </div>
    </div>
  </div>
</template>
<script>
export default {

  data() {
    return {
     language: '',
      buttons: [
        {text: "EN", role: "EN", id: 1, flag: 'reino-unido.png'},
        {text: "ES", role: "ES", id: 2, flag: 'esp.png'},
      ],
    };
  },

  mounted() {
    this.language = localStorage.language;
  },
  watch: {
    "$store.state.language": function() {
      this.language = this.$store.getters.getLanguage;
    }
  },
  computed: {
    userAccessRoute() {
      return this.$route.path.includes('questionnaire') || this.$route.path.includes('admin')
    },
    getLanguage: {
      get() {return this.$store.getters.getLanguage}
    }
  },
  methods: {
    changeLanguage(lan) {
      this.$store.commit('changeLanguage', lan)
    },
    logout() {
      localStorage.removeItem('userData');
      localStorage.removeItem('hasPermission');
      this.$router.push('/')
    }
  },
}

</script>
<style lang="scss">
@import 'src/assets/scss/colors';

#userOptions {
  width: 30px;
  height: 30px;
}

.dropdown-item:active {
  background-color: #23af4d !important;
}

.language-icon {
  width: 18px;
  margin: 0 5px;
  object-fit: cover;
}

.button-language {
  margin: 0 5px;
  &:hover {
    cursor: pointer;
    transition: opacity 200ms ease;
    opacity: 0.8;
  }
}

.bg-green {
  background-color: $wet-green !important;
  color: $wet-white !important;
}

.bg-white {
  background-color: $wet-white !important;
  color: $wet-green !important;
}

.btn-icon {
  aspect-ratio: 1.1;
  border: 2px solid $wet-green;
  background-color: $wet-white;
  border-radius: 8px;
  display: grid;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  margin: 0 10px;
  padding: 0;
  place-items: center;
  transition: background-color 0.3s ease-out;

  &.reverse:hover {
    filter: brightness(90%);
  }

  .language-button:focus, .language-button:active {
    background-color: red;
  }

  &:hover, &.reverse {
    background-color: $wet-green;
    color: white;
  }

  &:first-child {
    margin: 0 10px 0 0;
  }

  &:last-child {
    margin: 0;
  }

  img {
    aspect-ratio: 1;
    height: 60%;
  }
}
</style>
