<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import { consultLanguage } from "./helpers/idioma";
import { getData } from "./helpers/contenido";
console.log(Loader);
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    async changeLanguage(language) {
      let result = await consultLanguage(language);
      this.$store.commit("changeLanguage", result.data);
    },
    async getAllData(lan) {
      this.$store.commit("ActivaLoader", true);
      const response = await getData(lan);
      this.$store.commit("ActivaLoader", false);
      return response;
    },
  },
  mounted() {
    if (localStorage.language) {
      this.$store.state.language = localStorage.language;
    } else {
      this.changeLanguage(
        window.navigator.language || window.navigator.userLanguage
      );
    }
    this.getAllData(this.$store.state.language);
  },
  watch: {
    "$store.state.language": function (nuevo) {
      this.getAllData(nuevo);
    },
  },
};
</script>

<style lang="scss">
@use "assets/scss/colors";
@use "assets/scss/buttons";
@use "assets/scss/inputs";
@use "assets/scss/typography";
@use "assets/scss/fonts";
@import "assets/scss/common";
@import "node_modules/bootstrap/scss/bootstrap.scss";
body {
  overflow-x: hidden !important;
}
</style>
