import Vue from 'vue'
import VueRouter from 'vue-router'
import QuestionnarieMenu from "../views/Questionnaire/QuestionnaireMenu";
import AssesmentQuestionnaire from "../views/Questionnaire/AssesmentQuestionnaire";
import QuestionaireLayout from "../layouts/QuestionaireLayout";
import Valorization from "../views/Questionnaire/Valorization";
import Results from "../views/Questionnaire/Results";
import FormCompleted from '../views/Questionnaire/FormCompleted';
import AdminLayout from "../layouts/AdminLayout";
import Answers from "../views/admin/Answers";
import AnswerDetail from "../views/admin/AnswerDetail";
//import ComingSoon from "../views/ComingSoon";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Auth/Register')
    },
    {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        component: () => import('../views/Auth/PasswordRecovery')
    },
    {
        path: '/password-reset/:key',
        name: 'PasswordReset',
        component: () => import('../views/Auth/PasswordReset')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Auth/Login')
    },
    {
        path: '/questionnaire',
        name: 'Questionnaire',
        children: [
            {path: '', component: QuestionnarieMenu},
            {path: 'assesment', component: AssesmentQuestionnaire},
            {path: 'assesment/completed', component: FormCompleted},
            {path: 'valorization', component: Valorization},
            {path: 'valorization/completed', component: FormCompleted},
            {path: 'results', component: Results},
        ],
        component: QuestionaireLayout,
        beforeEnter(to, from, next) {
            if (localStorage.hasPermission) {
                next()
            } else {
                next({
                    name: "Login" // back to safety route //
                });
            }
        },
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AdminLayout,
        beforeEnter(to, from, next) {
            if (localStorage.hasPermission) {
                next()
            } else {
                next({
                    name: "Login" // back to safety route //
                });
            }
        },
        children: [
            {path: '', component: Answers },
            {path: 'answers/:id', component: AnswerDetail},
            {path: 'banner', component: () => import('../components/banners/BannerAdmin') },
            {path: 'desafios', component: () => import('../components/challenges/ProblemasAdmin') },
            {path: 'modeloBanner', component: () => import('../components/models-programs/ModeloBannerAdmin')},
            {path: 'modelo8x8', component: () => import('../components/models-programs/Modelo8x8Admin')},
            {path: 'modeloLean', component: () => import('../components/models-programs/ModeloLeanAdmin')},
            {path: 'strategicAdvisor', component: () => import('../components/models-programs/StrategicAdvisorAdmin')},
            {path: 'programs', component: () => import('../components/models-programs/ProgramasAdmin')},
            {path: 'desarrolloEmpresarial', component: () => import('../components/models-programs/DesarrolloEmpresarialAdmin')},
            {path: 'diferenciador', component: () => import('../components/models-programs/DiferenciadoresAdmin')},
            {path: 'puente', component: () => import('../components/models-programs/PuenteAdmin')},
            {path: 'comunidad', component: () => import('../components/models-programs/ComunidadBannerAdmin')},
            {path: 'testimonios', component: () => import('../components/equipo-experiencia/TestimoniosAdmin.vue')},
            {path: 'valores', component: () => import('../components/quienes-somos/ValoresAdmin.vue')},
            {path: 'nosPreocupamos', component: () => import('../components/quienes-somos/NosPreocupamosAdmin')},
            {path: 'registro', component: () => import('../views/Auth/RegisterAdmin.vue')},
            {path: 'resultados', component: () => import('../views/Questionnaire/ResultsAdmin.vue')},
            {path: 'equipoBanner', component: () => import('../components/equipo-experiencia/EquipoBannerAdmin')},
            {path: 'equipo', component: () => import('../components/equipo-experiencia/EquipoAdmin')},
            {path: 'experiencia', component: () => import('../components/equipo-experiencia/ExperienciaAdmin')},
            {path: 'nosotrosBanner', component: () => import('../components/quienes-somos/QuienesSomosBannerAdmin')},
            {path: 'nosotros', component: () => import('../components/quienes-somos/QuienesSomosAdmin')},
        ]
    },

    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        redirect: '/',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
