<template>
  <div @click="log()">
    <Radar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>

</template>

<script>
import {Radar} from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  RadialLinearScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  PointElement,
  RadialLinearScale,
  LineElement
)
// import planetChartData from '../utils/planetChartData'

export default {
  name: "AssesmentChart",
  components: {Radar},
  props: {
    companyName: String,
    companyPoints: Array,
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [
          'Alineamiento Estrategico',
          'Organizacion',
          'Capital Humano',
          'Inversion',
          'Finanzas',
          'Innovacion',
          'Operaciones',
          'Comercial'
        ],
        datasets: [
          {
            label: '0',
            backgroundColor: '#fff',
            borderColor: '#fff',
            pointBackgroundColor: '#fff',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: [0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            label: this.companyName,
            backgroundColor: 'rgb(102, 153, 255)',
            borderColor: 'rgb(102, 153, 255)',
            pointBackgroundColor: 'rgb(102, 153, 255)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(102, 153, 255)',
            data: this.companyPoints
          },
          {
            label: 'Minimo recomendado',
            backgroundColor: 'rgb(67 188 54)',
            borderColor: 'rgb(67 188 54)',
            pointBackgroundColor: 'rgba(127, 17, 224, 1)',
            pointBorderColor: 'rgba(127, 17, 224, 1)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: [80, 80, 80, 80, 80, 80, 80, 80]
          },
          {
            label: 'Optimo',
            backgroundColor: 'rgb(235 175 24)',
            borderColor: 'rgb(235 175 24)',
            pointBackgroundColor: 'rgba(255,99,132,1)',
            pointBorderColor: 'rgba(255,99,132,1)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255,99,132,1)',
            data: [100, 100, 100, 100, 100, 100, 100, 100]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  methods: {
    log() {
      console.log('props', this.props);
    }
  },
  mounted() {
    console.log(this.companyPoints)
  }
}
</script>

<style scoped>

</style>
