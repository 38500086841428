<template>
  <div class="Loader">
    <div></div>
    <div></div>
    <div></div>
    <div>
      <img src="@/assets/img/logo-loader.png" alt="logo wetforest animacion cargando" width="120px" >
    </div>

  </div>
</template>

<script>

export default {
  name: "Loader",
  props: {
    loader: Boolean,
  },
  data() {
    return {

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.Loader {
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  position:fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;

  @keyframes pulse {
    0%{transform:scale(.1);opacity: 0;}
    50%{ opacity: .4;}
    100%{transform: scale(1.6); opacity: 0;}
  }
  div {
    //background-color: rgba(200, 199, 199, 0.5);
    background-color: rgba(131, 183, 147, 1);
    border-radius: 50%;
    position: absolute;
    margin: auto auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    height: 200px;
  }

  div:nth-child(1){
    animation: pulse 2s infinite;
  }
  div:nth-child(2){
    animation: pulse 2s infinite .3s;
  }
  div:nth-child(3){
    animation: pulse 2s infinite .6s;
  }

  div:nth-child(4){
    text-align: center;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #22475E;
    font-size: 14px;
    line-height: 80px;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.loader-out {
  background-color: white;
  position:fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

.loader-container {
  width: 150px;
  height: 150px;
  color: white;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 5px solid #83b793;
  border-radius: 50%;
  -webkit-animation: borderScale 1s infinite ease-in-out;
  animation: borderScale 1s infinite ease-in-out;
}

.loading-text {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}







</style>
