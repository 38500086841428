export const assesmentQuestionInitialData = {
  order: 1,
  section: "",
  title: "",
  description: "",
  type: "",
  result: {
    answer: null
  },
  options: [],
}

export const questionnairePageInitialData = {
  title: "Cuestionarios",
  description:
    "Te invitamos a completar estos dos cuestionarios y obtendrás un informe preliminar del estado de desarrollo de tu empresa y un rango de su valorización.",
  buttonText: "Enviar mis respuestas",
  questionnaires: [
    {
      id: 1,
      title: "Assessment",
      path: "assesment",
      preTitle: "Parte 1",
      description:
        "Con este levantamiento obtendrás información preliminar sobre el estado actual de la gestión de tu empresa basado en cada uno de los 8 pilares del Modelo Wetforest para priorizar las acciones de mejora.",
      totalQuestion: 16,
      totalAnswer: 16,
      finishedText: "Sin contestar",
      totalQuestionText: "Total preguntas",
      buttonText: "Responder",
    },
    {
      id: 2,
      title: "Valorización",
      preTitle: "Parte 2",
      path: "valorization",
      description:
        "Con esta información obtendrás una valorización aproximada de tu empresa en función del estado de desarrollo en que se encuentra.",
      totalQuestion: 16,
      totalAnswer: 16,
      finishedText: "Sin contestar",
      totalQuestionText: "Total preguntas",
      buttonText: "Responder",
    },
  ],
}

export const multipleWithImagesInitialData = {
  order: 1,
  title: "Industria o Sector",
  section: "valorization",
  description: "Marque todas las que apliquen",
  type: "multipleWithImages",
  options: {
    alternative: {
      text: "¿Cuál otro rubro?",
      placeholder: "Escribir texto",
      value: "texto alternativo"
    },
    values: [
      {
        img: "icono_media_entertainment.svg",
        title: "Media & Entertainment",
        value: false,
        order: 1,
      },
      {
        img: "icono_tech_products.svg",
        title: "Tech products & Services",
        value: false,
        order: 2,
      },
      {
        img: "icono_retail_ecommerce.svg",
        title: "Retail & E-Commerce",
        value: false,
        order: 3,
      },
      {
        img: "icono_finance_fintech.svg",
        title: "Finance - Fintech",
        value: false,
        order: 4,
      },
      {
        img: "icono_insurance_insurtech.svg",
        title: "Insurance - Insurtech",
        value: false,
        order: 5,
      },
      {
        img: "icono_telecom.svg",
        title: "Telecom",
        value: false,
        order: 6,
      },
      {
        img: "icono_consumer_packaged.svg",
        title: "Consumer Packaged Goods (Cpg)",
        value: false,
        order: 7,
      },
      {
        img: "icono_education_edutech.svg",
        title: "Education & Knowledge - Edutech",
        value: false,
        order: 8,
      },
      {
        img: "icono_hospitality_tourism.svg",
        title: "Hospitality & Tourism",
        value: false,
        order: 9,
      },
      {
        img: "icono_manufacturing.svg",
        title: "Manufacturing",
        value: false,
        order: 10,
      },
      {
        img: "icono_transportation_logistics.svg",
        title: "Transportation & Logistics",
        value: false,
        order: 11,
      },
      {
        img: "icono_car_cartech.svg",
        title: "Cars & Cartech",
        value: false,
        order: 12,
      },
      {
        img: "icono_real_state.svg",
        title: "Real State - Propertech",
        value: false,
        order: 13,
      },
      {
        img: "icono_building_construtech.svg",
        title: "Building - Construtech",
        value: false,
        order: 14,
      },
      {
        img: "icono_healthcare_pharma.svg",
        title: "Healthcare & Pharma",
        value: false,
        order: 15,
      },
      {
        img: "icono_energy_utilities.svg",
        title: "Energy, Utilities & Chemicals",
        value: false,
        order: 16,
      },
      {
        img: "icono_mining_minetech.svg",
        title: "Mining - Minetech",
        value: false,
        order: 17,
      },
      {
        img: "icono_agriculture.svg",
        title: "Agriculture - Agritech",
        value: false,
        order: 18,
      },
      {
        img: "icono_goverment.svg",
        title: "Government",
        value: false,
        order: 19,
      },
      {
        img: "icono_marketing.svg",
        title: "Marketing & Advertising - Martech & Adtech",
        value: false,
        order: 20,
      },
      {
        img: "icono_human_resource.svg",
        title: "Human Resource - Jobtech",
        value: false,
        order: 21,
      },
      {
        img: "icono_legal_legaltech.svg",
        title: "Legal - Legaltech",
        value: false,
        order: 22,
      },
      {
        img: "icono_profesional_services.svg",
        title: "Professional Services & Consulting",
        value: false,
        order: 23,
      },
      {
        img: "icono_smartcity.svg",
        title: "SmartCity",
        value: false,
        order: 24,
      },
      {
        img: "icono_saas_empresarial.svg",
        title: "SaaS Empresarial Logistics",
        value: false,
        order: 25,
      },
      {
        img: "icono_logistics.svg",
        title: "Logistics",
        value: false,
        order: 26,
      },
      {
        img: "icono_others.svg",
        title: "Others",
        value: false,
        order: 27,
      },
    ]
  },
  answer: [],
}

export const counterQuestionInitialData = {
  "order": 2,
  "title": "Cantidad socios fundadores que trabajan en la empresa.",
  "section": "valorization",
  "description": "",
  "type": "counter",
  "options": {
    "value": 0,
  },
  "answer": 0,
}
export const ProjectionsInitialData = {
  "order": 4,
  "title": "Indique los ingresos que ha percibido su empresa y los que proyecta para los años sucesivos.",
  "section": "valorization",
  "description": "",
  "type": "multiNumberAmount",
  "options": {
    "values": [
      {
        "text": "Real 2019",
        "value": 0,
        "order": 1
      },
      {
        "text": "Real 2020",
        "value": 0,
        "order": 2
      },
      {
        "text": "Real 2021",
        "value": 0,
        "order": 3
      },
      {
        "text": "Proyectado 2022",
        "value": 0,
        "order": 4
      },
      {
        "text": "Proyectado 2023",
        "value": 0,
        "order": 5
      },
      {
        "text": "Proyectado 2024",
        "value": 0,
        "order": 6
      },
      {
        "text": "Proyectado 2026",
        "value": 0,
        "order": 7
      },
    ],
  },
  "result": {
    "answer": [0, 0, 0, 0, 0, 0, 0],
  },
}
export const CollaboratorsInitialData = {
  "order": 5,
  "title": "Cantidad de colaboradores contratados.",
  "section": "valorization",
  "description": "",
  "type": "multiNumberAmount",
  "options": {
    "values": [
      {
        "text": "Real Diciembre 2019:",
        "value": 0,
        "order": 1
      },
      {
        "text": "Real Diciembre 2020:",
        "value": 0,
        "order": 2
      },
      {
        "text": "Real Diciembre 2021:",
        "value": 0,
        "order": 3
      },
      {
        "text": "Proyectado Diciembre 2022:",
        "value": 0,
        "order": 4
      },
      {
        "text": "Proyectado Diciembre 2023:",
        "value": 0,
        "order": 5
      },
      {
        "text": "Proyectado Diciembre 2024:",
        "value": 0,
        "order": 6
      },
    ],
  },
  "language": "ES",
  "result": {
    "answer": [0, 0, 0, 0, 0, 0, 0],
  },
}

export const radioInitialData = {
  "order": 3,
  "section": "valorization",
  "title": " ¿En qué etapa de desarrollo empresarial se encuentra la empresa ahora?. ",
  "description": "",
  "type": "radio",
  "result": {
    "answer": null
  },
  "options": [
    {
      "title": "Idea",
      "text": "<p>Trabajando en el plan de negocio y probando los supuestos iniciales de solución de problemas.</p>",
      "order": 1
    },
    {
      "title": "Desarrollo",
      "text": "<p>Trabajando en el producto y estableciento operaciones.<br />Primeros aportes de organizaciones que promueven el emprendimiento y de capital semilla.</p>",
      "order": 2
    },
    {
      "title": "Puesta en Marcha",
      "text": "<p>Ya activa con ingresos de hasta US$ 200.000 anuales.<br />Gran meta Mínimo Producto Viable (MVP) y un demostrable y validado Product Market FIT (PMF).</p>",
      "order": 3
    },
    {
      "title": "Expansión",
      "text": "<p>Activa con ingresos superiores a US$ 200.000 está buscando ampliar sus operaciones.<br/>Gran meta construir un modelo de crecimiento repetible y escalable. <br /> Aportes de capital semilla. </p>",
      "order": 4
    },
    {
      "title": "Escalamiento",
      "text": "<p>Con modelo de crecimiento, repetible y escalable alcanzado un substancial incremento de ingresos. <br />Rondas de inversión de series A, B y siguientes.</p>",
      "order": 5
    },
    {
      "title": "Madurez",
      "text": "<p>Establemente rentable, con una organización y equipo gerencial completo.</p>",
      "order": 6
    },
  ],
  "language": "ES",
}
export const checkboxInitialData = {
  "order": 6,
  "section": "valorization",
  "title": " ¿Qué tipo de propiedad intelectual (P.I.) se aplica al producto/servicio?.",
  "description": "",
  "type": "checkbox",
  "result": {
    "answer": null
  },
  "options": [
    {"order": 1, "text": "Registros de Marca y/o Nombre de dominios."},
    {"order": 2, "text": "Copyright."},
    {"order": 3, "text": "Patente (excluida la patente provisional de los Estados Unidos)."},
    {"order": 4, "text": "Otros."},
  ],
  "language": "ES",
}
export const yesNotInitialData = {
  "order": 7,
  "section": "valorization",
  "title": " ¿Está la empresa en un punto de equilibrio sostenible?.",
  "description": "",
  "type": "switch",
  "result": {
    "answer": null
  },
  "options": {},
  "language": "ES",
}

export const investmentInitialData = {
  "order": 8,
  "section": "valorization",
  "title": "Inversión privada recibida.",
  "description": "",
  "type": "numberInput",
  "result": {
    "answer": null
  },
  "options": {
    "text": "Ingresar aproximado",
    "value": 0,
  },
  "language": "ES",
}
export const descriptionData = {
  "order": 10,
  "section": "valorization",
  "title": " Descripción del negocio.",
  "description": "",
  "type": "description",
  "result": {
    "answer": null
  },
  "options": {
    "placeholder": "Escribir Texto",
    "value": "",
  },
  "language": "ES",
}

export const countriesInitialData = {
  "order": 9,
  "section": "valorization",
  "title": " Mi empresa está y estará operativa en…",
  "description": "",
  "type": "countries",
  "result": {
    "answer": {
      "alternative": {
        "value": "",
        "option1": false,
        "option2": false
      },
      "options": [
        {"name": "Argentina", "option1": false, "option2": false },
        {"name": "Bolivia", "option1": false, "option2": false },
        {"name": "Brasil", "option1": false, "option2": false },
        {"name": "Chile", "option1": false, "option2": false },
        {"name": "Colombia", "option1": false, "option2": false },
        {"name": "Ecuador", "option1": false, "option2": false },
        {"name": "México", "option1": false, "option2": false },
        {"name": "Paraguay", "option1": false, "option2": false },
        {"name": "Uruguay", "option1": false, "option2": false },
        {"name": "Venezuela", "option1": false, "option2": false },
        {"name": "Caribe", "option1": false, "option2": false },
        {"name": "Centroamérica", "option1": false, "option2": false },
        {"name": "Canada", "option1": false, "option2": false },
        {"name": "USA", "option1": false, "option2": false },
        {"name": "Europa", "option1": false, "option2": false },
        {"name": "Asia", "option1": false, "option2": false },
        {"name": "Oceanía", "option1": false, "option2": false },
        {"name": "Africa", "option1": false, "option2": false },
      ]
    }
  },
  "options": {
    "head": {
      "sector": "Continentes",
      "option1": "Al 2021",
      "option2": "En 18 meses",
    },
    "values": [
      {
        "title":  "Americas",
        "order": 1,
        "countries": [
          {"order": 1, "name": "Argentina", "option1": false, "option2": false },
          {"order": 2, "name": "Bolivia", "option1": false, "option2": false },
          {"order": 3, "name": "Brasil", "option1": false, "option2": false },
          {"order": 4, "name": "Chile", "option1": false, "option2": false },
          {"order": 5, "name": "Colombia", "option1": false, "option2": false },
          {"order": 6, "name": "Ecuador", "option1": false, "option2": false },
          {"order": 7, "name": "México", "option1": false, "option2": false },
          {"order": 8, "name": "Paraguay", "option1": false, "option2": false },
          {"order": 9, "name": "Uruguay", "option1": false, "option2": false },
          {"order": 10, "name": "Venezuela", "option1": false, "option2": false },
          {"order": 11, "name": "Caribe", "option1": false, "option2": false },
          {"order": 12, "name": "Centroamérica", "option1": false, "option2": false },
          {"order": 13, "name": "Canada", "option1": false, "option2": false },
          {"order": 14, "name": "USA", "option1": false, "option2": false },
        ]
      },
      {
        "title":  "Otros Continentes",
        "order": 2,
        "countries": [
          {"order": 15, "name": "Europa", "option1": false, "option2": false },
          {"order": 16, "name": "Asia", "option1": false, "option2": false },
          {"order": 17, "name": "Oceanía", "option1": false, "option2": false },
          {"order": 18, "name": "Africa", "option1": false, "option2": false },
        ]
      },
    ],
    "alternative" : {
      "text": "Otro",
      "placeholder": "Escribir aqui",
      "value": "",
      "option1": false,
      "option2": false
    }
  },
  "language": "ES",
}


