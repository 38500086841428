import {SERVER} from "@/config";
import store from '../store';
import Vue from 'vue';
import axios from '../axios'

export async function getAnswersList() {
  let response;
  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Usuario/getAll`,
      {
        method: "GET",
        headers: {"Content-type": "application/json;charset=UTF-8"},
      }
    );

    response = await raw.json();

  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return response;
}

export async function getAnswerById(id) {
  let response;
  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Usuario/${id}`,
      {
        method: "GET",
        headers: {"Content-type": "application/json;charset=UTF-8"},
      }
    );

    response = await raw.json();

  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return response[0];
}

// the only one using axios...
export async function sendAnswerByWf(data) {
  let response;
  store.commit('ActivaLoader', true);
  try {
    response = await axios.post(`${SERVER}/Answer/sendAnswerbyWetforest`, data)

    if (response.status === 200 ) {
      Vue.swal(
        "Enhorabuena!",
        "¡Se cargaron las respuestas ajustadas y completas!",
        "success"
      )
    }

  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return response;
}

export async function deleteAnswers(data) {
  let response;
  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Answer/deleteAnswerbyWetforest`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(data)
      }
    );

    response = await raw.json();

    if (response.status === 500) {
      Vue.swal(
        "Oops!",
        response.message,
        "error"
      )
    }

  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  store.commit('ActivaLoader', false);
  return response;
}

export async function publishAnswers(data) {
  let response;
  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Answer/confirmAnswerbyWetforest`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(data)
      }
    );

    response = await raw.json();

    if (response.status === 500) {
      Vue.swal(
        "Oops!",
        response.message,
        "error"
      )
    }
    store.commit('ActivaLoader', false);

  } catch (error) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }

  return response;

}
