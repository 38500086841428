<template>
  <div class="container question" v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }" :key="order">
    <div class="row">
      <div class="progress w-100 mb-3 p-0" style="height: 5px">
        <div class="progress-bar bg-success p-0" role="progressbar"
             :style="`width: ${100 / this.$store.getters.getQuestionnaireInfo.data.totalQuestionAssesment * order }%`"
             aria-valuenow="25"
             aria-valuemin="0"
             aria-valuemax="100"></div>
      </div>
      <div>
        <p>{{ this.$store.getters.getQuestionText }} {{ order }} /
          {{ this.$store.getters.getQuestionnaireInfo.data.totalQuestionAssesment }}</p>
        <h2 class="text-success fw-bold">{{ this.$store.getters.getCurrentSectionTitle }}</h2>
      </div>
      <div class="col-12 col col-md-5 my-4 ">
        <div class="d-flex align-items-center mb-2 ">
          <h1 class="m-0"><span style="color: #23af4d; font-weight: bold">{{ question.order }}. </span>{{
              question.title
            }}</h1>
        </div>
        <div>{{ question.description }}</div>
      </div>
      <div class="col-12 col col-md-7 ">
        <div
          class="option shadow-sm"
          @click="handleAnswerQuestion(i + 1)"
          :class="{'selected': question.result && question.result.answer && question.result.answer === i + 1}"
          v-for="(option, i) in question.options" :key="i + 1">
          <p class="mb-0 ms-4">{{ option.text }}</p>
        </div>
      </div>
    </div>
    <div class="row buttons my-5">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" :disabled="questionValidation"
                @click="handleNext">
          {{ $store.getters.getNextText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store';
import {sendQuestion} from '../../helpers/questions'
import Vue from "vue";
import VAnimateCss from 'v-animate-css';
import {assesmentQuestionInitialData} from "../../utils/questionsInitialData";

Vue.use(VAnimateCss);

export default {
  name: "AssesmentQuestionnaire",
  data() {
    return {
      section: 'assesment',
      order: 1,
      loading: false,
      progress: 0,
      question: assesmentQuestionInitialData
    }
  },
  computed: {
    questionValidation() {
      return !this.question.result || !this.question.result.answer || this.loading
    }
  },
  watch: {
    "$store.state.language": function () {
      this.fetchQuestion({section: 'assesment', order: this.order});
    },
    "$store.state.currentQuestion": function () {
      this.question = store.getters.getCurrentQuestion;
    },
    // "$store.state.questionnaireInfo": function () {
    //   if (store.getters.getQuestionnaireInfo.data.totalAnswersAssesment < 16) {
    //     this.order = store.getters.getQuestionnaireInfo.data.totalAnswersAssesment + 1;
    //   } else {
    //     this.order = 16
    //   }
    // },

  },
  created() {
    this.fetchQuestion({section: 'assesment', order: this.order});
    this.fetchQuestionnaireInfo();
  },
  methods: {
    fetchQuestionnaireInfo() {
      store.dispatch('fetchQuestionnaireInfo');
    },
    async fetchQuestion(body) {
      this.loading = true;
      await store.dispatch('fetchCurrentQuestion', body);
      this.loading = false;
    },
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'assesment',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.question.result.answer
      }
      this.loading = true;
      const response = await sendQuestion(data);
      if (this.order < store.getters.getQuestionnaireInfo.data.totalQuestionAssesment) {
        if (response.status === 200) {
          await this.fetchQuestion({section: 'assesment', order: this.order + 1})
          this.order++;
          this.loading = false;
        }
      } else {
        this.order = 0
        this.$router.push('/questionnaire/assesment/completed');
        this.loading = false;
      }
    },
    handlePrev() {
      if (this.order === 1) {
        this.$router.push('/questionnaire');
      } else {
        this.fetchQuestion({section: 'assesment', order: this.order - 1})
        this.order--;
      }
    },
    handleAnswerQuestion(value) {
      this.question.result.answer = value;
    }
  }
}
</script>

<style lang="scss" scoped>

.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon {
      fill: white;
    }
  }
}

.question {
  margin-top: 2rem;

  .question-order {
    padding: 0.2rem 0.5rem;
    background-color: rgba(35, 175, 77, 0.1);
    border-radius: 50%;
    border: 1px solid #23af4d;
  }

  h1 {
    font-size: 1.5rem !important;
    font-weight: bolder;
  }

  .option {
    padding: 1rem;
    cursor: pointer;
    margin: 0.5rem 0;
    position: relative;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid rgba(35, 175, 77, 1);
    }

    &::before {
      content: '';
      position: absolute;
      top: 40%;
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      border: 1px solid #23af4d;
      background-color: transparent;
    }

    &:hover::before {
      transition: background-color 200ms ease;
      background-color: #23af4d;
    }
  }

  .selected {
    background-color: rgba(35, 175, 77, 0.1);
    border: 1px solid rgba(35, 175, 77, 1);

    &::before {
      content: '';
      position: absolute;
      top: 40%;
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      border: 1px solid #23af4d;
      background-color: #23af4d;
    }
  }

  .buttons {
    button {
      margin: 0 0.5rem;

      &:disabled {
        filter: grayscale(100%);
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
}
</style>
