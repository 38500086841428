<template>
  <div>
    <NewMenu />
    <router-view></router-view>
  </div>
</template>

<script>
import NewMenu from '../components/layout/NewMenu'
export default {
  name: "QuestionaireLayout",
  components: {NewMenu}
}
</script>

<style scoped>

</style>
