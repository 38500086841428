<template>
  <div>
    <div class="container">
      <div class="searcher ">
        <div class="">
          <div class="form-group wet-form searcher-input">
            <label class="form-label">Resultados</label>
<!--            <input type="text" class="form-control pe-5" placeholder="Buscar" v-model="input">-->
<!--            <img src="../../assets/icons/search.png" width="20px" alt="">-->
          </div>
<!--          <button class="btn btn-success" @click="filterAnswers(input)">Buscar</button>-->
        </div>
      </div>

      <div class="custom-table">
        <div class="row my-5 fw-bold custom-table-header">
          <div class="col">
            <p>Empresa</p>
          </div>
          <div class="col">
            <p>Web</p>
          </div>
          <div class="col">
            <p>Nombre contacto</p>
          </div>
          <div class="col">
            <p>Nº contacto</p>
          </div>
          <div class="col">
            <p>Estado</p>
          </div>
          <div class="col">
            <p>Acciones</p>
          </div>
        </div>

        <div v-if="answers.length > 1">
          <div class="row custom-table-content" v-for="answer in answers.filter(x => x.rol === 'client')"
               :key="answer._id">
            <div class="col-2">
              <p class="fw-bold">{{ answer.empresa }}</p>
              <p>{{ answer.rut }}</p>
            </div>
            <div class="col-2">
              <p style="word-break: break-word">{{ answer.website }}</p>
            </div>
            <div class="col-2">
              <p style="word-break: break-word">{{ answer.contacto }}</p>
            </div>
            <div class="col-2">
              <p>{{ answer.telefono }}</p>
            </div>
            <div class="col-2">
              <div
                class="state "
                :class="{'bg-danger': answer.status === 0, 'bg-primary': answer.status === 1, 'bg-warning': answer.status === 2, 'bg-success': answer.status === 3}">
                <span>{{
                    answer.status === 0 ? 'Sin Respuestas' : answer.status === 1 ? 'Con Respuestas' : answer.status === 2 ? 'Respuestas Ajustadas' : 'Respuestas Enviadas'
                  }}</span>
              </div>
            </div>
            <div class="col-2">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-outline-light icon-button-custom" @click="goToDetail(answer._id)"
                        :disabled="answer.status === 0 ">
                  <img src="../../assets/icons/show.png" width="20px" alt="">
                </button>
                <button type="button" class="btn btn-outline-light icon-button-custom" @click="openModal(answer)"
                        :disabled="answer.status !== 1">
                  <img src="../../assets/icons/upload.png" width="20px" alt="">
                </button>
                <button type="button" class="btn btn-outline-light icon-button-custom" @click="deleteAnswer(answer._id)"
                        :disabled="answer.status === 0 || answer.status === 1  || answer.status === 3">
                  <img src="../../assets/icons/delete.png" width="20px" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadModal v-show="showModal" @close-modal="showModal = false" @reFetcher="getAnswers" :data="modalData"/>
    <DeleteModal v-show="showDeleteModal" @close-modal="showDeleteModal = false" @cancel="showDeleteModal = false"
                 @deleteFile="handleDelete"/>
  </div>
</template>

<script>
import UploadModal from '../../components/UploadModal'
import DeleteModal from '../../components/DeleteModal'
import {deleteAnswers, getAnswersList} from '../../helpers/admin';

export default {
  name: "Answers",
  components: {UploadModal, DeleteModal},
  props: {},
  data() {
    return {
      answers: [],
      id: null,
      input: '',
      email: '',
      showModal: false,
      showDeleteModal: false,
      modalData: {},
    }
  },
  mounted() {
    this.getAnswers();
  },
  computed: {},
  watch: {},
  methods: {
    goToDetail(id) {
      this.$router.push(`/admin/answers/${id}`)
    },
    openModal(answer) {
      this.modalData = answer;
      this.email = answer.email;
      this.id = answer._id;
      this.showModal = true;
    },
    async handleDelete() {
      const data = {
        idUser: this.id,
      }
      const response = await deleteAnswers(data);
      if (response.status === 200) {
        return await this.getAnswers()
      }
    },
    deleteAnswer(id) {
      this.id = id;
      this.showDeleteModal = true;
    },
    async getAnswers() {
      this.answers = await getAnswersList();
    },
    filterAnswers() {
    }
  },

}
</script>

<style scoped lang="scss">
.searcher-input {
  position: relative;
  width: 50%;

  & img {
    position: absolute;
    right: 2%;
    top: 62%;
  }
}

.custom-table-header {
  color: white;
  padding: 1rem;
  background-color: rgba(128, 128, 128, 0.4);
  border-radius: 15px;

  p {
    margin: 0;
  }
}

.custom-table-content {
  border-bottom: 1px solid lightgray;
  padding: 1rem;

  p {
    margin: 0;
  }
}

.state {
  padding: 5px;
  text-align: center;
  border-radius: 15px;
  color: white;
  font-weight: bold;
}

.icon-button-custom:disabled {
  cursor: not-allowed !important;
}

p {
  font-size: 12px !important
}

</style>
