<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="custom-modal p-5" @click.stop>
      <div class="close text-end" @click="$emit('close-modal')">
        <img class="close-img" src="../assets/icons/close.png" alt="" />
      </div>
      <h5 class="border-bottom text-center pb-3">Cargar Respuestas</h5>
      <div class="d-flex flex-column justify-content-between h-75">
        <div>
          <p class="mb-0">Empresa</p>
          <h5>{{data.empresa}}</h5>
          <small><b>Cargar Respuestas</b> <span style="color:gray;">(Cargar tus respuestas en archivo Excel)</span></small>
          <div class="my-3 form-group wet-form position-relative">
            <img src="../assets/icons/excel-icon.png" class="excel" width="25px" alt="">
            <input class="form-control form-control-sm pe-5" ref="fileInput" id="fileInput" @input="uploadingFile($event.target.files[0])" type="file" accept=".xlsx, .xls, .csv">
          </div>
        </div>
        <div class="text-end">
          <button @click="saveFile" class="btn btn-success" :disabled="!file || loading">{{ loading ? 'Cargando...' : 'Guardar' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sendAnswerByWf} from "../helpers/admin";

export default {
  name: "UploadModal",
  components: {},
  props: {
    type: String,
    data: Object
  },
  data() {
    return {
      file: null,
      loading: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    uploadingFile(event) {
      this.file = event;
    },
    async saveFile() {
      let data = new FormData()
      data.append('file', this.file);
      data.append('idUser', this.data._id);
      data.append('email', this.data.email);
      this.loading = true;
      const response = await sendAnswerByWf(data)
      this.loading = false;
      if (response.status === 200) {
        this.$emit('reFetcher')
        this.$emit('close-modal');
      }
    },
  }
}
</script>

<style scoped>
.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.custom-modal {
  background-color: white;
  height: 350px;
  width: 500px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
}
.close {
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}


.excel {
  position: absolute;
  right: 3%;
  top: 10%;
}
</style>
