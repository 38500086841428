import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/js/bootstrap.js"
// import 'bootstrap-icons/font/bootstrap-icons.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CKEditor from '@ckeditor/ckeditor5-vue2';

import VJsoneditor from 'v-jsoneditor/src/index'

import AOS from 'aos'
import 'aos/dist/aos.css'

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = true


Vue.prototype.$Idioma = "ESPANOL"
Vue.prototype.$store = "ESPANOL"

Vue.use(VueSweetalert2).use( CKEditor ).use(VJsoneditor);




new Vue({
  router,
  store,
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')

