<template>
  <div class="container" v-if="answer">
    <button class="btn my-3" @click="$router.back()">
      <img src="../../assets/icons/close.png" width="40px" alt="" />
    </button>
    <div class="header text-center">
      <h4>
        {{ displayTitle() }}
        <span
          :class="{
            'text-primary': answer.status === 1,
            'text-warning': answer.status === 2,
            'text-success': answer.status === 3,
          }"
        >
          {{ displayState() }}
        </span>
      </h4>
      <div class="my-4">
        <button
          class="btn btn-success mx-2"
          @click="() => publishAnswers()"
          v-show="answer.status === 2"
        >
          {{ sectionData.publishButtonText }}
        </button>
      </div>
    </div>
    <div class="resume shadow-sm p-4 border">
      <h5 class="border-bottom pb-3">{{ sectionData.title }}</h5>
      <h6>{{ sectionData.companyData.title }}</h6>
      <div class="row">
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.rut }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.rut || "---" }}</p>
        </div>
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.company }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.empresa || "---" }}</p>
        </div>
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.date }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>
            {{
              answer && answer.sendDate
                ? answer.sendDate.substring(0, 10)
                : "---"
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.country }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.pais || "---" }}</p>
        </div>
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.web }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.website || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.industry }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>
            {{
              answer.result.wfResponse.preliminaryValorization.values
                .industry || "---"
            }}
          </p>
        </div>
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.businessActivity }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>
            {{
              answer.result.wfResponse.preliminaryValorization.values
                .businessActivity || "---"
            }}
          </p>
        </div>
        <div class="col-2">
          <p>
            <b>{{ sectionData.companyData.developmentPhase }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.businessPhase || "---" }}</p>
        </div>
      </div>

      <h6>{{ sectionData.contactData.title }}</h6>
      <div class="row">
        <div class="col-2">
          <p>
            <b>{{ sectionData.contactData.name }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.contacto || "---" }}</p>
        </div>
        <div class="col-2 offset-4">
          <p>
            <b>{{ sectionData.contactData.phone }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.telefono || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <p>
            <b>{{ sectionData.contactData.email }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.email || "---" }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <p>
            <b>{{ sectionData.contactData.founders }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.founders || "---" }}</p>
        </div>
        <div class="col-2">
          <p>
            <b>{{ sectionData.contactData.openSince }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.anoInicio || "---" }}</p>
        </div>
        <div class="col-2">
          <p>
            <b>{{ sectionData.contactData.employees }}</b>
          </p>
        </div>
        <div class="col-2">
          <p>{{ answer.result.valorization.employees }}</p>
        </div>
      </div>
      <h6>{{ sectionData.contactData.businessDescription }}</h6>
      <p>{{ answer.result.valorization.businessDescription || "---" }}</p>
    </div>
    <div class="bg-sweet-green py-4 my-4">
      <div class="row">
        <div class="col-10 px-0 offset-1 custom-card">
          <div class="card shadow-sm p-4 position-relative">
            <img src="../../assets/icons/estrella.png" class="star" alt="" />
            <div v-html="sectionData.totalAnswersText" />
          </div>
        </div>
      </div>
    </div>
    <DesarrolloEmpresarial :completed="false" />
    <div class="bg-sweet-green py-4 my-4">
      <div class="row">
        <div class="col-10 px-0 offset-1 custom-card">
          <div class="card shadow-sm p-4 position-relative">
            <img src="../../assets/icons/estrella.png" class="star" alt="" />
            <div class="d-flex border-bottom mb-4 pb-3 align-items-center">
              <p class="mb-0 me-3">{{ sectionData.phase }}</p>
              <span class="green-text">{{
                answer.result.valorization.businessPhase || "---"
              }}</span>
            </div>
            <!--            <div v-html="sectionData.phaseList"/>-->
            <ul>
              <li
                :key="index + 1"
                v-for="(option, index) in validateBusinessPhase(
                  answer.result.valorization.businessPhase
                )"
              >
                <p>{{ option.text }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="assesment my-5">
      <h2 class="text-center">{{ sectionData.assessment.title }}</h2>
      <div class="px-5 my-4">
        <div v-html="sectionData.assessment.text" />
      </div>
    </div>
    <div class="preliminar">
      <h2 class="text-center mb-5">
        {{ sectionData.assessment.preliminary }} <br /><span
          style="color: #23af4d"
          >{{ sectionData.assessment.company }}:
          {{ answer.empresa || "---" }}</span
        >
      </h2>
      <div
        class="row"
        v-if="
          validationAssesment(
            answer.result.wfResponse.preliminaryAssesment.values
          )
        "
      >
        <div class="col-12 col-md-3 me-2 bg-sweet-green shadow-sm p-3 h-100">
          <div>
            <h5 style="word-break: break-word">
              {{ answer.empresa || "---" }}
            </h5>
            <h5 class="text-center blue-border">
              {{ sum8x8(answer.result.wfResponse.preliminaryAssesment.values) }}
              %
            </h5>
          </div>
          <div>
            <h5>{{ sectionData.assessment.chartLegend.recommended }}</h5>
            <h5 class="text-center green-border">80%</h5>
          </div>
          <div>
            <h5>{{ sectionData.assessment.chartLegend.optimus }}</h5>
            <h5 class="text-center brown-border">100%</h5>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <AssesmentChart
            :company-name="answer.empresa"
            :company-points="
              Object.values(
                answer.result.wfResponse.preliminaryAssesment.values
              )
            "
          />
        </div>
      </div>
    </div>
    <div class="valorizacion bg-sweet-green my-5 p-5">
      <div v-html="sectionData.valorization.title" />
      <div v-html="sectionData.valorization.text" />
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="sweet-blue-card shadow-sm">
            <img src="../../assets/icons/low-bound.png" width="40px" alt="" />
            <p>{{ sectionData.valorization.lowBound }}</p>
            <h5
              style="color: #1d70b7"
              v-if="
                answer.result.wfResponse.preliminaryValorization.values
                  .highBound
              "
            >
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .lowBound
                )
              }}
            </h5>
            <p v-else>---</p>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="sweet-green-card shadow-sm">
            <p>{{ sectionData.valorization.preMoneyEvaluation }}</p>
            <h3
              style="color: #18a03e"
              v-if="
                answer.result.wfResponse.preliminaryValorization.values
                  .preMoneyValuation
              "
            >
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .preMoneyValuation
                )
              }}
            </h3>
            <p v-else>---</p>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="sweet-blue-card shadow-sm">
            <img src="../../assets/icons/high-bound.png" width="40px" alt="" />
            <p>{{ sectionData.valorization.highBound }}</p>
            <h5
              style="color: #1d70b7"
              v-if="
                answer.result.wfResponse.preliminaryValorization.values
                  .highBound
              "
            >
              US$
              {{
                numberWithCommas(
                  answer.result.wfResponse.preliminaryValorization.values
                    .highBound
                )
              }}
            </h5>
            <p v-else>---</p>
          </div>
        </div>
      </div>
      <div class="py-4 my-4">
        <div class="row">
          <div class="col-10 px-0 offset-1 custom-card">
            <div class="card shadow-sm p-4 position-relative">
              <img src="../../assets/icons/estrella.png" class="star" alt="" />
              <div
                class="d-flex border-bottom mb-4 pb-3 align-items-center justify-content-center"
              >
                <p class="mb-0 me-3">
                  {{ sectionData.valorization.preliminary }}
                </p>
                <span class="green-text"
                  >{{ sectionData.valorization.company }}
                  {{ answer.empresa || "---" }}</span
                >
              </div>
              <h6>{{ sectionData.valorization.nextStep }}:</h6>
              <p>{{ sectionData.valorization.nextStepText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-3">
      <button class="btn btn-success" @click="$router.back()">
        {{ sectionData.goBackButton }}
      </button>
    </div>
    <UploadModal
      v-show="showModal"
      @close-modal="showModal = false"
      @reFetcher="getAnswer(path)"
      :data="modalData"
    />
  </div>
</template>

<script>
import { getAnswerById, publishAnswers } from "../../helpers/admin";
import UploadModal from "../../components/UploadModal";
import DesarrolloEmpresarial from "../../components/models-programs/DesarrolloEmpresarial";
import AssesmentChart from "../../components/AssesmentChart";
import store from "../../store";
import { result } from "../../utils/mockResultsData";

export default {
  name: "AnswerDetail",
  components: { DesarrolloEmpresarial, UploadModal, AssesmentChart },
  data() {
    return {
      sectionData: result,
      answer: {},
      path: this.$route.params.id,
      //
      id: null,
      email: "",
      showModal: false,
      modalData: {},
    };
  },
  methods: {
    validationAssesment(obj) {
      return Object.values(obj).every((x) => x !== null);
    },
    sum8x8(obj) {
      const values = Object.values(obj);
      const calc = values.reduce((prev, next) => prev + next);
      return Math.round(calc / 8);
    },
    async publishAnswers() {
      const data = {
        idUser: this.answer._id,
        email: this.answer.email,
      };
      const response = await publishAnswers(data);
      if (response.status === 200) {
        this.$router.back();
      }
    },
    openModal(answer) {
      this.modalData = answer;
      this.email = answer.email;
      this.id = answer._id;
      this.showModal = true;
    },
    async getAnswer(id) {
      const response = await getAnswerById(id);
      this.answer = response;
    },
    fetchData() {
      if (store.state.sections.result) {
        this.sectionData = store.state.sections.result.components;
      }
    },
    numberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validateBusinessPhase(phase) {
      let fase = "";
      if (localStorage.language == "EN") {
        fase =
          phase == "ESCALAMIENTO"
            ? "escalation"
            : phase === "IDEA"
            ? "idea"
            : phase === "DESARROLLO"
            ? "develompent"
            : phase === "PUESTA EN MARCHA"
            ? "start up"
            : phase === "EXPANSION"
            ? "expansion"
            : phase === "ESCALAMIENTO"
            ? "escalation"
            : "maturity";
      } else {
        fase = phase.toLowerCase();
      }

      console.log(fase);
      console.log(this.sectionData.phaseList);
      const validation = this.sectionData.phaseList.filter(
        (x) => x.title.toLowerCase() === fase
      );
      return validation[0].options;
    },
    displayState() {
      if (localStorage.language == "ES") {
        const respuesta =
          this.answer.status === 1
            ? "Resultados disponibles para cargar"
            : this.answer.status === 2
            ? "Resultados listos para publicarse"
            : "Resultados publicados";

        console.log(respuesta);
        return respuesta;
      }
      if (localStorage.language == "EN") {
        const respuesta =
          this.answer.status === 1
            ? "Results available to upload"
            : this.answer.status === 2
            ? "Results ready to be published"
            : "Published results";

        return respuesta;
      }
    },

    displayTitle() {
      if (localStorage.language == "ES") {
        return "Estado:";
      }
      if (localStorage.language == "EN") {
        return "State:";
      }
    },
  },
  mounted() {
    this.getAnswer(this.path);
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
};
</script>

<style scoped lang="scss">
.resume {
  border-radius: 15px;
}

h6 {
  margin: 1rem 0;
}

.row {
  margin: 0.5rem 0;
}

.custom-card {
  border: 1px solid yellow;
}

.star {
  position: absolute;
  width: 30px;
  top: -12px;
  left: -12px;
}

.green-text {
  color: white;
  background-color: #23af4d;
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: bold;
}

.bg-sweet-green {
  background-color: #effff4;
  border-radius: 10px;
}

.blue-border::after {
  content: "";
  display: inherit;
  width: 50%;
  margin: 10px auto;
  border-top: 3px solid rgb(102, 153, 255);
}

.green-border::after {
  content: "";
  display: inherit;
  width: 50%;
  margin: 10px auto;
  border-top: 3px solid #23af4d;
}

.brown-border::after {
  content: "";
  display: inherit;
  width: 50%;
  margin: 10px auto;
  border-top: 3px solid rgb(204, 153, 0);
}

.sweet-blue-card {
  border-radius: 5px;
  background-color: #c2edf9;
  text-align: center;
  padding: 1rem;
}

.sweet-green-card {
  margin-top: 1rem;
  text-align: center;
  background-color: #bfefcc;
  border-radius: 5px;
  padding: 2rem;
}
</style>
