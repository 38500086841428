<template>
  <div>
    <NewMenu />
    <div class="wrapper">
      <div
        class="sidebar shadow"
        :class="{ 'sidebar-show': showSidebar, 'sidebar-hide': !showSidebar }"
      >
        <div class="text-end">
          <!--          <svg xmlns="http://www.w3.org/2000/svg" width="23" class="arrow-icon" @click="showSidebar = !showSidebar"  v-show="showSidebar" height="23" viewBox="0 0 19 23" fill="#ffff">-->
          <!--            <path d="M0 11.5L18.75 0.674683L18.75 22.3253L0 11.5Z" class="icon"/>-->
          <!--          </svg>-->
          <!--          <svg xmlns="http://www.w3.org/2000/svg" class="arrow-icon" @click="showSidebar = !showSidebar"  v-show="!showSidebar" width="23" height="23" viewBox="0 0 19 23" fill="#ffff">-->
          <!--            <path d="M0.249634 22.3253L0.249634 0.674683L18.9996 11.5L0.249634 22.3253Z" class="icon"/>-->
          <!--          </svg>-->
        </div>
        <div class="nav w-100">
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin' }"
            @click="$router.push('/admin')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Respuestas</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/banner' }"
            @click="$router.push('/admin/banner')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Banner</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/desafios' }"
            @click="$router.push('/admin/desafios')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Desafios</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/modeloBanner' }"
            @click="$router.push('/admin/modeloBanner')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Modelos y <br />
              programas (Banner)
            </p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/modelo8x8' }"
            @click="$router.push('/admin/modelo8x8')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Modelos y <br />
              programas (8x8)
            </p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/modeloLean' }"
            @click="$router.push('/admin/modeloLean')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Modelos y <br />
              programas (Lean)
            </p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{
              selected: $route.path === '/admin/StrategicAdvisor',
            }"
            @click="$router.push('/admin/strategicAdvisor')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Strategic <br />
              Advisors
            </p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{
              selected: $route.path === '/admin/programs',
            }"
            @click="$router.push('/admin/programs')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Programas</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{
              selected: $route.path === '/admin/desarrolloEmpresarial',
            }"
            @click="$router.push('/admin/desarrolloEmpresarial')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Desarrollo <br />
              empresarial
            </p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{
              selected: $route.path === '/admin/diferenciador',
            }"
            @click="$router.push('/admin/diferenciador')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Diferenciador</p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{
              selected: $route.path === '/admin/puente',
            }"
            @click="$router.push('/admin/puente')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Somos un <br />
              excelente puente
            </p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{
              selected: $route.path === '/admin/comunidad',
            }"
            @click="$router.push('/admin/comunidad')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Comunidad</p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/equipoBanner' }"
            @click="$router.push('/admin/equipoBanner')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Equipo y <br />
              experiencia (Banner)
            </p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/equipo' }"
            @click="$router.push('/admin/equipo')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Equipo</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/experiencia' }"
            @click="$router.push('/admin/experiencia')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Experiencia</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{
              selected: $route.path === '/admin/testimonios',
            }"
            @click="$router.push('/admin/testimonios')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Testimonios</p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/nosotrosBanner' }"
            @click="$router.push('/admin/nosotrosBanner')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Quienes somos (Banner)</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/nosotros' }"
            @click="$router.push('/admin/nosotros')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Quienes somos (Contenido)</p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/valores' }"
            @click="$router.push('/admin/valores')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Valores</p>
          </div>
          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/nosPreocupamos' }"
            @click="$router.push('/admin/nosPreocupamos')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Nos preocupamos <br />
              por ti
            </p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/registro' }"
            @click="$router.push('/admin/registro')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">
              Registro <br />
              nuevo usuario
            </p>
          </div>

          <div
            class="nav-item w-100 d-flex"
            :class="{ selected: $route.path === '/admin/resultados' }"
            @click="$router.push('/admin/resultados')"
          >
            <img
              src="../assets/icons/document.svg"
              class="me-2"
              width="20px"
              alt=""
            />
            <p class="m-0">Resultados</p>
          </div>
        </div>
      </div>
      <div
        class="content"
        :class="{
          'content-full': !showSidebar,
          'content-divided': showSidebar,
        }"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NewMenu from "../components/layout/NewMenu";
export default {
  name: "AdminLayout",
  components: { NewMenu },
  data() {
    return {
      showSidebar: false,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.inverted {
  transform: rotate(180deg);
}

.sidebar-show {
  flex: 0.15;
}

.sidebar-hide {
  flex: 0.15;
}

.content-divided {
  flex: 0.85;
}

.content-full {
  flex: 0.95;
}
.wrapper {
  display: flex;

  .sidebar {
    color: white;
    min-height: 100vh;
    background-color: #23af4d;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    .nav {
      padding: 1rem 0 1rem 1rem;

      .nav-item {
        padding: 1rem 0 1rem 1rem;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.2);
          transition: background-color 200ms ease;
        }
      }

      .selected {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .arrow-icon {
      margin: 1rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
p.m-0 {
  font-size: 1rem !important;
}
</style>
