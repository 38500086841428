<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="custom-modal px-5 pt-5" @click.stop>
      <h5 class="border-bottom text-center pb-3">Desea eliminar este documento?</h5>
      <div class="">
        <div class="text-end mt-5">
          <button @click="deleteFile" class="btn btn-danger mx-2">Eliminar</button>
          <button @click="$emit('cancel')" class="btn btn-light mx-2">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteModal",
  components: {},
  data() {
    return {
      file: null,
    }
  },
  mounted() {
  },
  computed: {},
  watch: {},
  methods: {
    deleteFile() {
      this.$emit('close-modal')
      this.$emit('deleteFile')
    }
  },

}
</script>

<style scoped>
.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.custom-modal {
  background-color: white;
  height: 200px;
  width: 500px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}


.excel {
  position: absolute;
  right: 3%;
  top: 10%;
}
</style>
