import {SERVER} from "@/config";
import axios from "axios";
import store from "@/store";
import Vue from "vue";


export async function getData(lan) {
  const URL = SERVER + '/Contenido';
  let result;
  let sections = {};
  try {
    const response = await fetch(
      `${URL}/getall`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify({language: lan})
      },
    );
    result = await response.json();
    sections.menu = result.filter(x => x.section === 'menu')[0];
    sections.banner = result.filter(x => x.section === 'banner')[0];
    sections.challenges = result.filter(x => x.section === 'challenges')[0];
    sections.modelBanner = result.filter(x => x.section === 'modelBanner')[0];
    sections.differBanner = result.filter(x => x.section === 'differBanner')[0];
    sections.wetforest8x8 = result.filter(x => x.section === 'wetforest8x8')[0];
    sections.modelLeanBanner = result.filter(x => x.section === 'modelLeanBanner')[0];
    sections.strategicAdvisors = result.filter(x => x.section === 'strategicAdvisors')[0];
    sections.communityBanner = result.filter(x => x.section === 'communityBanner')[0];
    sections.bridge = result.filter(x => x.section === 'bridge')[0];
    sections.enterpriseDevelopment = result.filter(x => x.section === 'enterpriseDevelopment')[0];
    sections.programs = result.filter(x => x.section === 'programs')[0];
    sections.team = result.filter(x => x.section === 'team')[0];
    sections.aboutUs = result.filter(x => x.section === 'aboutUs')[0];
    sections.teamExperience = result.filter(x => x.section === 'teamExperience')[0];
    sections.contact = result.filter(x => x.section === 'contact')[0];
    sections.testimonies = result.filter(x => x.section === 'testimonies')[0];
    sections.aboutUsBanner = result.filter(x => x.section === 'aboutUsBanner')[0];
    sections.aboutUs = result.filter(x => x.section === 'aboutUs')[0];
    sections.expertise = result.filter(x => x.section === 'expertise')[0];
    sections.values = result.filter(x => x.section === 'values')[0];
    sections.floatSection = result.filter(x => x.section === 'floatSection')[0];
    sections.careAboutYou = result.filter(x => x.section === 'careAboutYou')[0];
    sections.login = result.filter(x => x.section === 'login')[0];
    sections.register = result.filter(x => x.section === 'register')[0];
    sections.passwordRecovery = result.filter(x => x.section === 'passwordRecovery')[0];
    sections.passwordReset = result.filter(x => x.section === 'passwordReset')[0];
    sections.forgotPassword = result.filter(x => x.section === 'forgotPassword')[0];
    sections.questionnaires = result.filter(x => x.section === 'questionnaires')[0];
    sections.assessmentSuccess = result.filter(x => x.section === 'assessmentSuccess')[0];
    sections.valorizationSuccess = result.filter(x => x.section === 'valorizationSuccess')[0];
    sections.result = result.filter(x => x.section === 'result')[0];
    sections.modals = result.filter(x => x.section === 'modals')[0];
    store.commit('section', sections);


  } catch (e) {
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  return (result);
}

export async function getSection(section) {
  const URL = SERVER + '/Contenido';
  let result;
  try {
    const response = await fetch(
      `${URL}/${section}`,
      {
        method: "GET",
        headers: {"Content-type": "application/json;charset=UTF-8"},
      },
    );
    result = await response.json();
    return result;

} catch(e) {
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
}}

export async function putSection(json) {
  const URL = SERVER + '/Contenido'; try {
    const response = await axios.put(`${URL}/section`, json);
    if (response.data.status == 200) {
      Vue.swal(
        "Wetforest",
        "Actualizado correctamente",
        "success"
      );
    } else {
      Vue.swal(
        "Wetforest",
        "Hubo problema al actualizar la información",
        "error"
      );
    }
  } catch(e) {
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
}}
