<template>
  <nav :class="{'dropdown-opened': nav}">
    <img
      @click="$router.push('/')"
      src="@/assets/logo/logo.webp"
      class="logo"
      loading="lazy"
      alt="wetForest-brand-logo"
    />
    <UserOptions class="d-flex d-md-none "/>
    <!-- Cool hamburger icon -->
    <img width="30px" class="mobile-dropdown-toggle" @click="nav = !nav" aria-hidden="true"
         :src="!nav ? require(`../../assets/icons/menu-bars.svg`) : require(`../../assets/img/x-icon-menu.webp`)"
         alt="menu-toggle"/>


    <div class="dropdown-link-container">
      <div class="d-flex flex-column flex-md-row text-end text-md-center" v-if="this.$route.name === 'Home' ">
        <a :href="link.href" @click="toggleNav" v-for="link in links" class="my-2 my-md-0  nav-link" :key="link.id">{{
            link.text
          }}</a>
      </div>
      <div class="d-flex flex-column flex-md-row text-end text-md-center" v-if="formRoute">
        <a :href="link.href" @click="handleClickOutsideRoot" v-for="link in links" class="my-2 my-md-0  nav-link"
           :key="link.id">{{
            link.text
          }}</a>
      </div>
      <div class="d-flex flex-column flex-md-row text-end align-items-center" v-if="this.$route.path.includes('questionnaire')  ">
        <img src="@/assets/icons/home-icon.svg" width="25px" alt="" class="me-2 home-button" @click="$router.push('/questionnaire')">
        <p class="m-0"> {{ $store.getters.getLanguage === 'ES' ? 'Bienvenido' : 'Welcome' }}!,<span class="username"> {{ username.contacto }}</span></p>
      </div>
    </div>
    <UserOptions class="d-none d-md-flex"/>

  </nav>

</template>

<script>
import UserOptions from "../Navigation/UserOptions";
import store from "../../store";

export default {
  name: "NewMenu",
  components: {UserOptions},
  data() {
    return {
      username: JSON.parse(localStorage.getItem('userData')),
      nav: false,
      links: [],
    }
  },
  computed: {
    formRoute() {
      return this.$route.name === 'Login'
        || this.$route.name === 'Register'
        || this.$route.name === 'PasswordReset'
        || this.$route.name === 'PasswordRecovery'
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    toggleNav() {
      this.nav = false
    },
    handleClickOutsideRoot() {
      this.toggleNav();
      this.$router.push('/');
    },
    fetchData() {
      if (store.state.sections.menu) {
        this.links = store.state.sections.menu.components.links;
      }
    },
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
}
</script>

<style scoped lang="scss">
nav {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  padding: 1rem 2rem;
  /*max-width: 1200px; !* should match the width of your website content *!*/
  display: flex;
  justify-content: space-between;
  align-items: center; /* center each of our links vertically */
  margin: auto; /* center all our content horizontally when we exceed that max-width */
}

.home-button {
  cursor: pointer;
}

.nav-link {
  color: black;
  font-weight: bold;
  border-bottom: 5px solid transparent;

  &:hover {
    color: #23af4d;
    border-bottom: 5px solid #23af4d;
  }

  &:active {
    color: #23af4d;
    border-bottom: 5px solid #23af4d;
  }
}

.logo {
  /*margin-right: auto; !* push all our links to the right side, leaving the logo on the left *!*/
  max-width: 135px;
}

.username {
  color: #23af4d;
  font-weight: bold;
}

.dropdown-link-container > a {
  margin-left: 20px; /* space out all our links */
}

.mobile-dropdown-toggle {
  display: none; /* hide our hamburger button until we're on mobile */
}


@media (max-width: 768px) {
  /* arbitrary breakpoint, around the size of a tablet */
  .dropdown-link-container {
    /* first, make our dropdown cover the screen */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    /* fix nav height on mobile safari, where 100vh is a little off */
    //height: -webkit-fill-available;

    /* then, arrange our links top to bottom */
    display: flex;
    flex-direction: column;
    /* center links vertically, push to the right horizontally.
       this means our links will line up with the rightward hamburger button */
    justify-content: center;
    align-items: flex-end;

    /* add margins and padding to taste */
    margin: 0;
    padding-left: 7vw;
    padding-right: 7vw;

    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1111;
  }
}

@media (max-width: 768px) {
  .logo, .mobile-dropdown-toggle {
    z-index: 1111;
  }

  .mobile-dropdown-toggle {
    display: initial; /* override that display: none attribute from before */
  }

  .dropdown-link-container {
    z-index: 1; /* we're gonna avoid using -1 here, since it could position our navbar below other content on the page as well! */
  }

  .dropdown-link-container {
    /* our initial state */
    opacity: 0; /* fade out */
    /* Comentar la propiedad "transform" para eliminar el "fade-up" */
    transform: translateY(-100%); /* move out of view */
    transition: transform 0.2s, opacity 0.2s; /* transition these smoothly */
  }

  nav.dropdown-opened > .dropdown-link-container {
    opacity: 1; /* fade in */
    /* Comentar la propiedad "transform" para eliminar el "fade-up" */
    transform: translateY(0); /* move into view */
  }

  .nav-link {
    font-size: 22px;
  }
}
</style>
