<template>
  <section id="DesarrolloEmpresarial" class="container my-5 py-5" data-aos="fade-up">
    <div class="text-center mb-5">
      <h2 class="mb-4 fw-bold">
        <span class="green__text">{{ data.greenTitle }}</span>
        {{ data.blackTitle }}
      </h2>
      <p v-show="completed">{{ data.description }}</p>
    </div>
    <img
      :src="require(`../../assets/${data.img}`)"
      class="graph d-none d-md-block"
      alt="Imagen de grafica de desarrollo empresarial"
    />
    <img
      :src="require(`../../assets/${data.imgMobile}`)"
      class="graph d-block d-md-none"
      alt="Imagen de grafica de desarrollo empresarial version movil"
    />
  </section>
</template>

<script>
import store from "../../store";

export default {
  name: "DesarrolloEmpresarial",
  props: {
    completed: Boolean,
    default: () => false,
  },
  data() {
    return {
      data: {
        greenTitle: "Etapas",
        blackTitle: "de Desarrollo Empresarial",
        description:
          "Los Programas Wetforest se configuran con el cliente en función de la etapa de desarrollo empresarial en que se encuentra.",
        img: "img/graph-es.webp",
        imgMobile: "img/graph-mobile-es.webp",
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      if (store.state.sections.enterpriseDevelopment) {
        this.data = store.state.sections.enterpriseDevelopment.components;
      }
    },
  },
};
</script>

<style scoped>
.green__text {
  color: #27ba54;
}

.graph {
  width: 100%;
}
</style>
