export const result = {
  "state": "Estado",
  "publishButtonText": "Publicar respuestas",
  "title": "Resumen de resultados",
  "companyData": {
    "title": "Datos de la empresa",
    "rut": "Rut",
    "company": "Empresa",
    "date": "Fecha",
    "country": "País",
    "web": "Web",
    "industry": "Industria",
    "businessActivity": "Actividad de negocio",
    "develpmentPhase": "Etapa de desarrollo",
  },
  "contactData": {
    "title": "Datos de contacto",
    "name": "Nombre de contacto",
    "email": "Mail",
    "phone": "Teléfono",
    "founders": "Fundadorres",
    "openSince": "Inicio de operaciones",
    "employees": "Cantiad de trabadores",
    "businessDescription": "Descripción de negocio",
  },
  "totalAnswersText": `
      <p>
        Ud. respondió <b>un total de 26 preguntas</b> que, una vez procesadas siguiendo nuestro Modelo Wetforest
        8X8, dan un primer diagnóstico del Estado de la Gestión y un rango de una Valorización Preliminar de su
        empresa en función de la Etapa de Desarrollo Empresarial en que se encuentra.
      </p>
    `,
  "phase": "Sus respuestas indican que su empresa se encuentra en la etapa de",
  "phaseList": `
      <ul>
        <li>Ya activa con ingresos hasta US$ 200.000 anuales.</li>
        <li>Gran meta Mínimo Producto Viable (MVP) y un demostrable y validado Product Market FIT (PMF).</li>
      </ul>
    `,
  "assessment": {
    "title": "Assessment Preliminar Wetforest 8x8",
    "text": `
         <p>
          El diagrama que se encuentra a continuación califica con un porcentaje el nivel de desarrollo de cada uno de
          los 8 Pilares de Gestión de su empresa, corresponden a Alineamiento Estratégico, Organización, Capital
          Humano
          e Inversión en el ámbito de la Estrategia y Finanzas, Innovación, Operaciones y Comercial en el ámbito de la
          Ejecución.
        </p>
        <p>
          El resultado del análisis preliminar de su empresa se muestra con la línea de color celeste, con la
          calificación promedio señalada. Se puede ver que no todos los Pilares de Gestión están igualmente
          desarrollados lo que significa que es necesario definir un Plan de Acción para ir reforzando los Pilares y
          sus
          respectivos ejes más débiles para facilitar el paso de su empresa desde la Etapa en que se encuentra a las
          siguientes etapas.
        </p>
        <p>
          Si su empresa está iniciando la Etapa de Escalamiento debiera acercarse tanto como sea posible al nivel
          mínimo
          recomendado de 80% (línea verde) o del nivel óptimo de 100% (línea gris) en el caso de estar en Etapas de
          Escalamiento sostenido y Madurez.
        </p>
      `,
    "preliminary": "Assessment Preliminar",
    "company": "Empresa",
    "chartLegend": {
      "recommended": "Minimo recomendado",
      "optimus": "Óptimo",
    },
  },
  "valorization": {
    "title": `<h2 class="text-center">Valorización Preliminar <br><span style="color: #23af4d">Wetforest 8x8</span></h2>`,
    "text": `
        <p class="px-5">En base a sus respuestas calculamos una Valorización Referencial (Pre-money Valuation) y un
        rango de
        valorización preliminar (Low/ High Bound) utilizando parámetros del mercado de empresas que operan en el mismo
        segmento, en la misma zona geográfica y en etapas de desarrollo similar como se indica en este cuadro:</p>
      `,
    "lowBound": "Low Bound",
    "preMoneyEvaluation": "Pre-Money Valuation",
    "highBound": "High Bound",
    "preliminary": "Valorización Preliminar",
    "company": "Empresa",
    "nextStep": "Siguiente paso",
    "nextStepText": `Para diseñar un Plan de Acción concreto para asegurar el viaje de su empresa por las distintas etapas
                de desarrollo y contar con una Valorización a través de 5 métodos ampliamente reconocidos nuestra
                recomendación es que se efectúe el Programa Assessment y Valorización Completo respondiendo 114
                preguntas adicionales complementado con 3 reuniones de trabajo con nuestros Strategic Advisors en un
                plazo de un mes.  `,
  },
  "goBackButton": 'Volver atras',
}
