<template>
  <div>
    <section class="px-3">
      <div class="row">
        <div class="col-12 col-md-5 d-flex align-items-start justify-content-center ">
          <div class="text-center">
            <h2>{{ data.title }}</h2>
            <img src="@/assets/img/missWetForest.png" class="my-4" alt="missWetForest-img" width="150px">
            <p class="my-0">{{ data.description }}</p>
            <button
              :disabled="getTotalQuestionAssessment !== getTotalAnswersAssessment || getTotalQuestionValorization !== getTotalAnswersValorization || userStatusValidation"
              class="btn btn-success mt-4 w-100 py-2 px-4 shadow" v-show="user.status === 0" @click="sendAnswers"
              type="button">
              {{ data.buttonText }}
            </button>
            <div class="shadow-sm p-4 mt-5" v-show="user.status === 3">
              <h6>{{$store.getters.getLanguage === 'ES' ? 'Tus respuestas fueron analizadas!' : 'Your answers have been analyzed!'}}</h6>
              <button class="btn btn-success btn-sm px-4 py-2" type="button"
                      @click="$router.push(`/questionnaire/results`)">
                {{ $store.getters.getLanguage === 'ES' ? 'Ver mis resultados!' : 'See my results!' }}
              </button>
            </div>
            <div class="shadow-sm p-4 mt-5" v-show="user.status === (1 || 2)">
              <h6>{{$store.getters.getWaitTitle}}</h6>
              <p>{{recommendationText()}}</p>
              <button class="btn btn-success w-100" @click="logout">{{ goBackText() }}</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 ">
          <div class="shadow-sm p-4 mb-4"
               :class="{'disabled': questionnaire.title !== 'Assessment' && getTotalQuestionAssessment !== getTotalAnswersAssessment || userStatusValidation}"
               v-for="(questionnaire, i) in data.questionnaires" :key="questionnaire.id">
            <h6 class="m-0">{{ questionnaire.preTitle }}</h6>
            <h5>{{ questionnaire.title }}</h5>
            <div class="w-100 d-flex justify-content-between">
              <small>{{validationCompletedQuestionnaire(i) }} </small>
              <small>{{ questionnaire.totalQuestionText }}:
                <b>{{ getTotalQuestionsByQuestionnaire(questionnaire.title) }}</b></small>
            </div>
            <div class="progress w-100 my-3" style="height: 5px">
              <div class="progress-bar bg-success " role="progressbar"
                   :style="`width: ${100 /getTotalQuestionsByQuestionnaire(questionnaire.title) * getTotalAnswersByQuestionnaire(questionnaire.title)}%`"
                   aria-valuenow="25"
                   aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
            <button class="btn btn-success btn-sm px-4 py-2" type="button"
                    @click="$router.push(`/questionnaire/${questionnaire.path}`)">
              {{ userStatusValidation ? questionnaireSentMessage() : questionnaire.buttonText }}
            </button>
            <p class="mt-2 mb-2">{{ questionnaire.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import store from "../../store";
import {questionnairePageInitialData} from "../../utils/questionsInitialData";
import {sendAnswers} from "../../helpers/questions";

export default {
  data() {
    return {
      data: questionnairePageInitialData,
      user: JSON.parse(localStorage.userData)
    };
  },
  computed: {
    getTotalQuestionAssessment() {
      return this.$store.getters.getQuestionnaireInfo.data.totalQuestionAssesment
    },
    getTotalAnswersAssessment() {
      return this.$store.getters.getQuestionnaireInfo.data.totalAnswersAssesment
    },
    getTotalQuestionValorization() {
      return this.$store.getters.getQuestionnaireInfo.data.totalQuestionValorization
    },
    getTotalAnswersValorization() {
      return this.$store.getters.getQuestionnaireInfo.data.totalAnswersValorizacion
    },
    userStatusValidation() {
      return this.user.status === 1 || this.user.status === 3 || this.user.status === 2
    }
  },
  mounted() {
    this.fetchData();
    this.fetchQuestionnaireInfo()
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    }
  },
  methods: {
    validationCompletedQuestionnaire(i) {
      let totalQuestion;
      let totalAnswer;
      const language = store.getters.getLanguage
      if (i === 0) {
        totalQuestion = store.getters.getQuestionnaireInfo.data.totalQuestionAssesment;
        totalAnswer = store.getters.getQuestionnaireInfo.data.totalAnswersAssesment;
        if (language === 'ES' ) {
          if ( totalAnswer > 0 && totalAnswer < totalQuestion) {
            return 'Incompleto'
          } else if (totalAnswer === 0) {
            return 'Sin Contestar'
          } else {
            return 'Completado!'
          }
        } else {
          if ( totalAnswer < totalQuestion) {
            return 'Incomplete'
          } else {
            return 'Completed!'
          }
        }

      } else if (i === 1) {
        totalQuestion = store.getters.getQuestionnaireInfo.data.totalQuestionValorization;
        totalAnswer = store.getters.getQuestionnaireInfo.data.totalAnswersValorizacion;
        if (language === 'ES' ) {
          if ( totalAnswer > 0 && totalAnswer < totalQuestion) {
            return 'Incompleto'
          } else if (totalAnswer === 0) {
            return 'Sin Contestar'
          } else {
            return 'Completado!'
          }
        } else {
          if ( totalAnswer < totalQuestion) {
            return 'Incomplete'
          } else {
            return 'Completed!'
          }
        }
      }
    },
    recommendationText() {
      const language = store.getters.getLanguage;
      if (language === 'ES') {
        return 'Te recomendamos salir de sesión hasta que recibas el correo con nuestro análisis, asi veras la última información de esta página'
      } else {
        return 'We recommend you to log out until you receive the email with our analysis, so you will see the latest information on this page'
      }
    },
    goBackText() {
      const language = store.getters.getLanguage;
      if (language === 'ES') {
        return 'Volver atras'
      } else {
        return 'Go back'
      }
    },
    logout() {
      localStorage.removeItem('userData');
      localStorage.removeItem('hasPermission');
      this.$router.push('/')
    },
    questionnaireSentMessage() {
      const language = localStorage.language;
      if (language === 'ES') {
        return 'Ya has enviado tus respuestas!'
      } else {
        return 'You have already sent your answers!'
      }
    },
    getTotalQuestionsByQuestionnaire(title) {
      if (title === 'Assessment') {
        return store.state.questionnaireInfo.data.totalQuestionAssesment;
      } else {
        return store.state.questionnaireInfo.data.totalQuestionValorization;
      }
    },
    getTotalAnswersByQuestionnaire(title) {
      if (title === 'Assessment') {
        return store.getters.getQuestionnaireInfo.data.totalAnswersAssesment;
      } else {
        return store.getters.getQuestionnaireInfo.data.totalAnswersValorizacion;
      }
    },
    fetchQuestionnaireInfo() {
      store.dispatch('fetchQuestionnaireInfo');
    },
    async fetchData() {
      if (store.state.sections.questionnaires) {
        this.data = store.state.sections.questionnaires.components;
      }
    },
    async sendAnswers() {
      const data = {
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        language: localStorage.language
      };
      await sendAnswers(data);
    }
  },
}

</script>
<style lang="scss">
button {
  &:disabled {
    filter: grayscale(100%);
  }
}

.disabled {
  filter: grayscale(100%);
  pointer-events: none;
}

p {
  font-size: 1rem !important
}

h6 {
  font-size: 1.1rem !important;
  color: #23af4d;
}
</style>
