<template v-if="$store.getters.getCurrentQuestion ">
  <div class="container question">
    <div class="progress w-100 mb-3 p-0" style="height: 5px">
      <div class="progress-bar bg-success p-0" role="progressbar"
           :style="`width: ${100 / this.$store.getters.getQuestionnaireInfo.data.totalQuestionValorization * order }%`"
           aria-valuenow="25"
           aria-valuemin="0"
           aria-valuemax="100"></div>
    </div>
    <div>
      <p>{{ this.$store.getters.getQuestionText }} {{ order }} /
        {{ this.$store.getters.getQuestionnaireInfo.data.totalQuestionValorization }}</p>
    </div>
    <!--    preguntas segun orden, las preguntas son distintas! por eso componentes diferentes la mayoria de casos-->
    <MultipleWithImages v-if="order === 1" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Counter v-if="order === 2" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Radio v-if="order === 3" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Projections v-if="order === 4" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Collaborators v-if="order === 5" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Checkbox v-if="order === 6" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <SwitchHand v-if="order === 7" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Investment v-if="order === 8" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Countries v-if="order === 9" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
    <Description v-if="order === 10" @sendQuestion="sendQuestion" @goPrev="handlePrev"/>
  </div>
</template>

<script>
import store from '../../store';
import {sendQuestion} from "../../helpers/questions";

export default {
  components: {
    MultipleWithImages: () => import('../../components/questions/MultipleWithImages'),
    Counter: () => import('../../components/questions/Counter'),
    Radio: () => import('../../components/questions/Radio'),
    Projections: () => import('../../components/questions/Projections'),
    Collaborators: () => import('../../components/questions/Collaborators'),
    Checkbox: () => import('../../components/questions/Checkbox'),
    SwitchHand: () => import('../../components/questions/SwitchHand'),
    Investment: () => import('../../components/questions/Investment'),
    Description: () => import('../../components/questions/Description'),
    Countries: () => import('../../components/questions/Countries'),
  },
  name: "Valorization",
  data() {
    return {
      //variable de control para manejar la siguiente pregunta o proxima pregunta
      //ademas para renderizar la barra de progreso
      order: 1,
    }
  },
  watch: {
    "$store.state.language": function () {
      //cada vez que cambie el idioma llamamos a la pregunta en la misma posicion con el idioma cambiado
      this.fetchQuestion({section: 'valorization', order: this.order});
    },
    // "$store.state.questionnaireInfo": function () {
    //   if (store.getters.getQuestionnaireInfo.data.totalAnswersValorizacion < 10) {
    //     this.order = store.getters.getQuestionnaireInfo.data.totalAnswersValorizacion + 1;
    //   } else {
    //     this.order = 10
    //   }
    // },
  },
  mounted() {
    // llamamos a la pregunta una vez renderizado el componente
    this.fetchQuestion({section: 'valorization', order: this.order});
    // llamamos a la data de el cuestionario para renderizar la barra de progreso
    this.fetchQuestionnaireInfo();
  },
  methods: {
    handlePrev() {
      if (this.order === 1) {
        // si la pregunta actual es la primera nos devolvemos a el menu de questionarios
        this.$router.push('/questionnaire');
      } else {
        // si no es la primera volvemos a la pregunta anterior y cambiamos la variable order a la pregunta actual
        this.fetchQuestion({section: 'valorization', order: this.order - 1})
        this.order--;
      }
    },
    async sendQuestion(data) {
      // recibe como body el json armado desde cada componente con la funcion $emit ... aqui solo manejamos el envio de data
      const response = await sendQuestion(data);
      if (this.order < store.getters.getQuestionnaireInfo.data.totalQuestionValorization) {
        // si la pregunta aun no es la ultima, pasaremos a la siguiente pregunta
        if (response.status === 200) {
          this.fetchQuestion({section: 'valorization', order: this.order + 1})
          this.order++;
        }
      } else {
        // si la pregunta es la ultima, mostraremos un modal, nos iremos al inicio y reseteamos la variable order
        // TODO dejar la variable order con el ultimo valor
        this.order = 0
        this.$router.push('/questionnaire/valorization/completed')
      }
    },
    fetchQuestionnaireInfo() {
      //trae la data de el questionario completo
      store.dispatch('fetchQuestionnaireInfo');
    },
    fetchQuestion(body) {
      // trae la data de la pregunta actual
      store.dispatch('fetchCurrentQuestion', body);
    },
  }
}
</script>

<style lang="scss" scoped>
.question {
  margin-top: 2rem;

  .question-order {
    padding: 0.2rem 0.5rem;
    background-color: rgba(35, 175, 77, 0.1);
    border-radius: 50%;
    border: 1px solid #23af4d;
  }

  h1 {
    font-size: 1.5rem !important;
    font-weight: bolder;
  }

  .option {
    padding: 1rem;
    cursor: pointer;
    margin: 0.5rem 0;
    border: 1px solid transparent;

    &:hover {
      transition: background-color 200ms ease;
      border: 1px solid rgba(35, 175, 77, 1);
      background: rgba(35, 175, 77, 0.1);
    }
  }

  .selected {
    background-color: rgba(35, 175, 77, 0.1);
    border: 1px solid rgba(35, 175, 77, 1);
  }
}
</style>
