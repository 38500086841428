import { render, staticRenderFns } from "./AssesmentQuestionnaire.vue?vue&type=template&id=926b0852&scoped=true&"
import script from "./AssesmentQuestionnaire.vue?vue&type=script&lang=js&"
export * from "./AssesmentQuestionnaire.vue?vue&type=script&lang=js&"
import style0 from "./AssesmentQuestionnaire.vue?vue&type=style&index=0&id=926b0852&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "926b0852",
  null
  
)

export default component.exports