import Vue from 'vue'
import Vuex from 'vuex'
import {SERVER} from "@/config";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: "ES",
    Loader: false,
    sections: {},
    allQuestions: [],
    sectionQuestions: [],
    currentQuestion: {},
    sectionTitle: '',
    questionnaireInfo: {
      data: {
        totalQuestionAssesment: 0,
        totalAnswersAssesment: 0,
        totalQuestionValorization: 0,
        totalAnswersValorizacion: 0
      }
    },
  },
  mutations: {
    changeLanguage(state, language) {
      this.state.language = language;
      this.state.lastMutation = 'CambiarIdioma'
      localStorage.language = language;
    },
    ActivaLoader(state, estado) {
      this.state.Loader = estado;
      this.state.lastMutation = 'ActivaLoader'
    },
    section(state, payload) {
      this.state.sections = payload;
    },
    handleSectionQuestions(state, payload) {
      this.state.sectionQuestions = payload;
    },
    handleCurrentQuestion(state, payload) {
      this.state.currentQuestion = payload;
    },
    handleQuestionnaireInfo(state, payload) {
      this.state.questionnaireInfo = payload;
    },
    handleCurrentSectionTitle(state, payload) {
      this.state.sectionTitle = payload;
    }
  },
  actions: {
    async fetchQuestionnaireInfo(context) {
      let info;
      const body = {
        idUser: JSON.parse(localStorage.userData)._id,
      };
      context.commit('ActivaLoader', true);
      try {
        const response = await fetch(
          `${SERVER}/Question/sectionByGroupbyUser`,
          {
            method: "POST",
            headers: {"Content-type": "application/json;charset=UTF-8"},
            body: JSON.stringify(body)
          },
        );
        info = await response.json();
        if (response.status === 200) {
          context.commit('handleQuestionnaireInfo', info)
        }
      } catch(e) {
        context.commit('ActivaLoader', false);
      }
      context.commit('ActivaLoader', false);
    },
    async fetchCurrentQuestion(context, data) {
      let question;
      const body = {
        section: data.section,
        language: this.state.language,
        order: data.order,
        idUser: JSON.parse(localStorage.userData)._id
      };
      // context.commit('ActivaLoader', true);
      try {
        const response = await fetch(
          `${SERVER}/Question/sectionByOrderUser`,
          {
            method: "POST",
            headers: {"Content-type": "application/json;charset=UTF-8"},
            body: JSON.stringify(body)
          },
        );
        question = await response.json();
        if (response.status === 200) {
          context.commit('handleCurrentQuestion', question[0])
          context.commit('handleCurrentSectionTitle', question[0].sectionTitle)
        }
      } catch(e) {
        // context.commit('ActivaLoader', false);
      }
      // context.commit('ActivaLoader', false);
    },

    async fetchQuestionsBySection(context, data) {
      let questions;
      const body = {
        section: data.section,
        language: this.state.language,
      }
      context.commit('ActivaLoader', true);
      try {
        const response = await fetch(
          `${SERVER}/Question/section`,
          {
            method: "POST",
            headers: {"Content-type": "application/json;charset=UTF-8"},
            body: JSON.stringify(body)
          },
        );
        questions = await response.json();
        if (response.status === 200) {
          context.commit('handleSectionQuestions', questions)
        }
      } catch(e) {
        context.commit('ActivaLoader', false)
      }
      context.commit('ActivaLoader', false)
    }
  },
  getters: {
    authHasPermission() { return localStorage.hasPermission},
    userData() { return localStorage.userData},
    getCurrentQuestion: (state) => state.currentQuestion,
    getCurrentSectionTitle : (state) => state.sectionTitle,
    getQuestionsBySection: (state) => state.sectionQuestions,
    getAllQuestions: (state) => state.allQuestions,
    getQuestionnaireInfo: (state) => state.questionnaireInfo,
    getLanguage: (state) => state.language,
    getModalsData: (state) => state.sections.modals,
    getUsdText: (state) => state.language === 'ES' ? 'Los valores deben ser en $USD (Dolar Americano)' : 'The amounts must be in $USD (American Dollar)',
    getQuestionText: (state) => state.language === 'ES' ? 'Pregunta' : 'Question',
    getPrevText: (state) => state.language === 'ES' ? 'Anterior' : 'Back',
    getNextText: (state) => state.language === 'ES' ? 'Siguiente' : 'Next',
    getWaitTitle: (state) => state.language === 'ES' ? 'Tus respuestas fueron enviadas!' : 'Your Answers have been sent!',
    getWaitText: (state) => state.language === 'ES' ? 'Por favor espera, estan en proceso de analisis' : 'Please wait, they are on the analysis period',
  },
    modules: {
  }
})
