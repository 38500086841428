import {SERVER} from "@/config";
import store from '../store';
import Vue from 'vue';

export async function sendQuestion(data) {
  let response;
  // store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Answer`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(data),
      }
    );

    response = await raw.json();

    if (response.status === 500) {
      Vue.swal(
        "oops!",
        response.message,
        "error"
      );
    }

  } catch (error) {
    // store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  // store.commit('ActivaLoader', false);
  return response;
}

export async function sendAnswers(data) {
  let response;
  store.commit('ActivaLoader', true);

  try {
    const raw = await fetch(
      `${SERVER}/Answer/sendAnswerbyUser`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(data),
      }
    );

    response = await raw.json();

    if (response.status === 500) {
      Vue.swal(
        "oops!",
        response.message,
        "error"
      );
    }

    if (response.status === 200) {
      const modalData = store.getters.getModalsData.components.sendAnswersModal;
      Vue.swal(
        modalData.title,
        modalData.text,
        "success"
      )
      const userData = {...JSON.parse(localStorage.userData), status: 1}
      localStorage.userData = JSON.stringify(userData);
      setTimeout(() => {
        location.reload();
      }, 100)
    }

  } catch (error) {
    store.commit('ActivaLoader', false);
    if (error) {
      Vue.swal(
        "oops!",
        error.message,
        "error"
      );
    }
  }
  store.commit('ActivaLoader', false);
  return response;
}

export async function fetchQuestionnaireInfo(data) {
  let info;
  const body = {
    section: data.section,
    language: this.state.language,
    idUser: JSON.parse(localStorage.userData)._id,
  };
  store.commit('ActivaLoader', true);
  try {
    const response = await fetch(
      `${SERVER}/Question/sectionByGroupbyUser`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify(body)
      },
    );
    info = await response.json();
    if (response.status === 200) {
      store.commit('handleQuestionnaireInfo', info)
    }
  } catch (e) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
}

export async function fetchQuestionnaireMenu() {
  let response;
  store.commit('ActivaLoader', true);
  try {
    const raw = await fetch(
      `${SERVER}/Question/getQuestionnaires`,
      {
        method: "POST",
        headers: {"Content-type": "application/json;charset=UTF-8"},
        body: JSON.stringify({language: localStorage.langauge})
      },
    );
    response = await raw.json();
  } catch (e) {
    store.commit('ActivaLoader', false);
    Vue.swal(
      "oops!",
      "Ocurrio un error enviando los datos, intentalo en unos minutos...",
      "error"
    );
  }
  return response;
}


